import request from '_utils/request'

const get_credit_report = () => {
  return request({
    url: '/api/userInfo/generateCreditReport',
    method: 'GET'
  })
}

export {
  get_credit_report
}
