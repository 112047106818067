<template>
  <div id="credit-report">
    <div
        class="loading"
        v-show="showLoading"
    >
      <img
          src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/credit-report/credit-report-icon.gif"
          alt=""
      />
      <div>征信报告查询中…</div>
      <div>请耐心等待</div>
    </div>
    <img
        :src="`https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/credit-report/credit-report-x${riskLabel}.png`"
        alt=""
        class="credit-report-label"
    />
    <main>
      <div class="user-info-container credit-report-info-container">
        <img
            src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/credit-report/credit-report-title1.png"
            alt=""
            class="credit-report-title"
        />
        <div class="user-name">
          <span>{{ creditReport.userInfoData.userName }}</span>
          <div>{{ creditReport.userInfoData.sex === 1 ? '男' : '女' }}</div>
          <em>{{ creditReport.userInfoData.age }}岁</em>
        </div>
        <div>手机号：{{ creditReport.userInfoData.phone }}</div>
        <div>身份证号：{{ creditReport.userInfoData.idCard }}</div>
        <div>报告编号：{{ creditReport.userInfoData.reportCode }}</div>
        <div>评估时间：{{ creditReport.userInfoData.estimateTime }}</div>
      </div>
      <div class="court-info-container credit-report-info-container">
        <img
            src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/credit-report/credit-report-title2.png"
            alt=""
            class="credit-report-title"
        />
        <div class="court-info-table">
          <div class="court-info-table-header">
            <div></div>
            <div>判定为限高案件数</div>
            <div>判定为失信案件数</div>
          </div>
          <div class="court-info-table-main">
            <div class="court-info-table-main-line">
              <div>近一年</div>
              <div v-html="getHtml(creditReport.executionLimitedData.limitYear1Num)"></div>
              <div v-html="getHtml(creditReport.executionLimitedData.promiseYear1Num)"></div>
            </div>
            <div class="court-info-table-main-line">
              <div>近二年</div>
              <div v-html="getHtml(creditReport.executionLimitedData.limitYear2Num)"></div>
              <div v-html="getHtml(creditReport.executionLimitedData.promiseYear2Num)"></div>
            </div>
            <div class="court-info-table-main-line">
              <div>近三年</div>
              <div v-html="getHtml(creditReport.executionLimitedData.limitYear3Num)"></div>
              <div v-html="getHtml(creditReport.executionLimitedData.promiseYear3Num)"></div>
            </div>
            <div class="court-info-table-main-line">
              <div>近四年</div>
              <div v-html="getHtml(creditReport.executionLimitedData.limitYear4Num)"></div>
              <div v-html="getHtml(creditReport.executionLimitedData.promiseYear4Num)"></div>
            </div>
            <div class="court-info-table-main-line">
              <div>近五年</div>
              <div v-html="getHtml(creditReport.executionLimitedData.limitYear5Num)"></div>
              <div v-html="getHtml(creditReport.executionLimitedData.promiseYear5Num)"></div>
            </div>
          </div>
        </div>
        <div class="court-info">
          <div>法院失信人名单 <span v-html="getHtml(creditReport.executionLimitedData.isPromise, 1)"></span></div>
          <div>限高记录已经全部下架 <span v-html="getHtml(creditReport.executionLimitedData.limitSign, 1)"></span></div>
          <div>法院被执行人名单 <span v-html="getHtml(creditReport.executionLimitedData.isLimit, 1)"></span></div>
          <div>失信记录已经全部下架 <span v-html="getHtml(creditReport.executionLimitedData.promiseSign, 1)"></span></div>
        </div>
      </div>
      <div class="special-list-info-container credit-report-info-container">
        <img
            src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/credit-report/credit-report-title3.png"
            alt=""
            class="credit-report-title"
        />
        <div class="special-list-info-table">
          <div class="special-list-info-table-header">
            <div></div>
            <div>高风险</div>
            <div>中风险</div>
            <div>一般风险</div>
          </div>
          <div class="special-list-info-table-main">
            <div class="special-list-info-table-main-line">
              <div>银行卡(含信用卡)</div>
              <div v-html="getHtml(creditReport.specialListData.slIdBankLost)"></div>
              <div v-html="getHtml(creditReport.specialListData.slIdBankBad)"></div>
              <div v-html="getHtml(creditReport.specialListData.slIdBankOverdue)"></div>
            </div>
            <div class="special-list-info-table-main-line">
              <div>持牌汽车金融</div>
              <div v-html="getHtml(creditReport.specialListData.slIdNbankAutofinLost)"></div>
              <div v-html="getHtml(creditReport.specialListData.slIdNbankAutofinBad)"></div>
              <div v-html="getHtml(creditReport.specialListData.slIdNbankAutofinOverdue)"></div>
            </div>
            <div class="special-list-info-table-main-line">
              <div>非银类型</div>
              <div v-html="getHtml(creditReport.specialListData.slIdNbankLost)"></div>
              <div v-html="getHtml(creditReport.specialListData.slIdNbankBad)"></div>
              <div v-html="getHtml(creditReport.specialListData.slIdNbankOverdue)"></div>
            </div>
            <div class="special-list-info-table-main-line">
              <div>持牌消费金融</div>
              <div v-html="getHtml(creditReport.specialListData.slIdNbankConsLost)"></div>
              <div v-html="getHtml(creditReport.specialListData.slIdNbankConsBad)"></div>
              <div v-html="getHtml(creditReport.specialListData.slIdNbankConsOverdue)"></div>
            </div>
            <div class="special-list-info-table-main-line">
              <div>融资租赁</div>
              <div v-html="getHtml(creditReport.specialListData.slIdNbankFinleaLost)"></div>
              <div v-html="getHtml(creditReport.specialListData.slIdNbankFinleaBad)"></div>
              <div v-html="getHtml(creditReport.specialListData.slIdNbankFinleaOverdue)"></div>
            </div>
            <div class="special-list-info-table-main-line">
              <div>持牌网络小贷</div>
              <div v-html="getHtml(creditReport.specialListData.slIdNbankNsloanLost)"></div>
              <div v-html="getHtml(creditReport.specialListData.slIdNbankNsloanBad)"></div>
              <div v-html="getHtml(creditReport.specialListData.slIdNbankNsloanOverdue)"></div>
            </div>
            <div class="special-list-info-table-main-line">
              <div>其他</div>
              <div v-html="getHtml(creditReport.specialListData.slIdNbankOtherLost)"></div>
              <div v-html="getHtml(creditReport.specialListData.slIdNbankOtherBad)"></div>
              <div v-html="getHtml(creditReport.specialListData.slIdNbankOtherOverdue)"></div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { get_credit_report } from '_network/creditReport'

export default {
  data() {
    return {
      showLoading: true,
      creditReport: {
        userInfoData: {},
        specialListData: {},
        executionLimitedData: {}
      },
      riskLabel: 1
    }
  },
  methods: {
    getHtml(value, special) {
      if (special === 1) {
        // 这里special为1是处理 法院失信人名单 限高记录已经全部下架 法院被执行人名单 失信记录已经全部下架 这4个特殊字段的
        // 0和null为未命中 1为命中
        if (value === 1) {
          return `<span style="font-weight: 500;font-size: 0.32rem;color: #FF4141;">命中</span>`
        } else {
          return `<span style="font-weight: 500;font-size: 0.32rem;color: #45BB4F;">未命中</span>`
        }
      } else {
        // 这里是其他字段null为未命中 有值则是命中
        if (value === null) {
          return `<span style="font-weight: 500;font-size: 0.32rem;color: #45BB4F;">未命中</span>`
        } else {
          return `<span style="font-weight: 500;font-size: 0.32rem;color: #FF4141;">命中</span>`
        }
      }
    },
    async getCreditReport() {
      try {
        const { data } = await get_credit_report()
        if (data) {
          this.creditReport = data
          this.riskLabel = data.riskTotalEvaluate
        }
      } catch (e) {}
    }
  },
  created() {
    document.title = '征信报告'
    this.getCreditReport()
  },
  mounted() {
    setTimeout(() => {
      this.showLoading = false
    }, 3000)
  }
}
</script>

<style lang="less" scoped>
#credit-report {
  min-height: 100vh;
  background: #F8F8F8;

  .loading {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    padding-top: 3.04rem;
    background: #FFF;
    font-size: 0.373rem;
    color: #666666;
    text-align: center;

    img {
      width: 3.733rem;
      margin: 0 auto;
    }

    div:first-of-type {
      margin: 0.507rem 0 0.213rem;
      font-size: 0.427rem;
      color: #333333;
    }
  }

  main {
    padding: 0 0.32rem 0.613rem;

    .credit-report-info-container {
      margin-top: 0.267rem;
      background: #FFFFFF;
      border-radius: 0.32rem;

      .credit-report-title {
        width: auto;
        height: 0.667rem;
      }
    }

    .user-info-container {
      padding: 0.32rem 0.347rem 0.72rem;
      font-size: 0.32rem;
      color: #333333;

      .user-name {
        display: flex;
        align-items: center;
        margin: 0.827rem 0 0.507rem;
        font-size: 0.32rem;
        color: #999999;

        span {
          font-weight: 600;
          font-size: 0.64rem;
          color: #333333;
        }

        div {
          margin: 0 0.187rem 0 0.453rem;
        }
      }

      & > div {
        margin-top: 0.32rem;
      }
    }

    .court-info-container {
      padding: 0.32rem 0.347rem 0.773rem;

      .court-info-table {
        margin: 0.347rem 0 0.427rem;

        .court-info-table-header,
        .court-info-table-main-line {
          div {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          div:nth-of-type(1) {
            flex: 1;
          }

          div:nth-of-type(n + 2) {
            width: 3.493rem;
          }
        }

        .court-info-table-header {
          display: flex;
          font-weight: 500;
          font-size: 0.32rem;
          color: #333333;

          div {
            height: 1.067rem;

            &:nth-of-type(1) {
              background: #F9F9F9;
            }

            &:nth-of-type(2) {
              background: #E7F0FF;
            }

            &:nth-of-type(3) {
              background: #FAF2EC;
            }
          }
        }

        .court-info-table-main-line {
          display: flex;
          align-items: center;
          height: 0.747rem;
          font-size: 0.267rem;
          color: #999999;

          &:nth-of-type(2n) {
            background: #F9F9F9;
          }
        }
      }

      .court-info {
        display: flex;
        flex-wrap: wrap;

        div {
          width: 50%;
          font-size: 0.32rem;
          color: #333333;

          &:nth-of-type(-n + 2) {
            margin-bottom: 0.16rem;
          }
        }
      }
    }

    .special-list-info-container {
      padding: 0.32rem 0.347rem 0.4rem;

      .special-list-info-table {
        margin-top: 0.347rem;

        .special-list-info-table-header,
        .special-list-info-table-main-line {
          display: flex;

          div {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.133rem;

            &:nth-of-type(1) {
              flex: 1;
            }
          }
        }

        .special-list-info-table-header {
          div {
            height: 1.067rem;
            
            &:nth-of-type(1) {
              background: #F9F9F9;
            }

            &:nth-of-type(2) {
              background: #FFE6E6;
            }

            &:nth-of-type(3) {
              background: #FFF3F0;
            }

            &:nth-of-type(4) {
              background: #FFF9EF;
            }
          }
        }

        .special-list-info-table-main-line {
          height: 0.747rem;
          font-size: 0.267rem;
          color: #999999;

          &:nth-of-type(2n) {
            background: #F9F9F9;
          }
        }
      }
    }
  }
}
</style>